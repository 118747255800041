import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import config from "../config.js";
import store from '../store/index.js';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/c/:captureId',
    name: 'Capture',
    //component: Capture,
    meta: {
      hideNavbar: true,
      hideFooter: true
    },
    component: () => import(/* webpackChunkName: "main" */'../views/Capture.vue')
  },
  {
    path: '/co/:collectionId',
    name: 'Collection',
    //component: Capture,
    meta: {
      hideNavbar: true,
      hideFooter: true
    },
    component: () => import(/* webpackChunkName: "main" */'../views/Collection.vue')
  },
  {
    path: '/about',
    name: 'About',
    //component: About,
    component: () => import(/* webpackChunkName: "main" */'../views/About.vue')
  },
  {
    path: '/features',
    name: 'Features',
    //component: Features
    component: () => import(/* webpackChunkName: "main" */'../views/Features.vue')
  },
  {
    path: '/terms',
    name: 'Terms of Service',
    component: () => import(/* webpackChunkName: "main" */'../views/TermsOfService.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "main" */'../views/PrivacyPolicy.vue')
  },
  {
    path: '/validation/:id',
    name: 'Validation',
    //component: Validation
    component: () => import(/* webpackChunkName: "main" */'../views/Validation.vue')
  },
  {
    path: '/forgot',
    name: 'ForgottenPassword',
    //component: ForgottenPassword,
    component: () => import(/* webpackChunkName: "main" */'../views/ForgottenPassword.vue'),
    meta: {
      hideNavbar: false,
      hideFooter: false
    }
  },
  {
    path: '/download',
    name: 'Download',
    //component: Download,
    component: () => import(/* webpackChunkName: "main" */'../views/Download.vue'),
    meta: {
      hideNavbar: false,
      hideFooter: false
    }
  },
  {
    path: '/reset/:id',
    name: 'ResetPassword',
    //component: ResetPassword
    component: () => import(/* webpackChunkName: "main" */'../views/Reset.vue'),
  },
  {
    path: '/delete/:id',
    name: 'DeleteAccount',
    //component: DeleteAccount
    component: () => import(/* webpackChunkName: "main" */'../views/Delete.vue'),
  },
  {
    path: '/404',
    name: 'PageNotFound',
    //component: PageNotFound,
    component: () => import(/* webpackChunkName: "main" */'../views/PageNotFound.vue'),
  },
  {
    path: '/admin',
    //component: Admin,
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access_token")) {
        const token = localStorage.getItem("access_token");
        fetch(`${config.URL}/verifytoken`, {
          method: "GET",
          headers: {
            Authorization:'Bearer ' + token,
            "x-access-token": token,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                if(data.userLevel <= 0){
                  next('/');
                }else{
                  next();
                }
              });
            }else{
              next('/');
            }
          })
          .catch((err) => {
            store.commit("disconnectUser"); next('/'); 
          });
      }else{
        store.commit("disconnectUser");
        next('/');
      }
    },
    meta: {
      hideNavbar: true,
      hideFooter: true
    },
    children: [
      {
        path: '',
        name: 'Admin',
        redirect:'/admin/dashboard',
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/Admin.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'ranks',
        name: 'Ranks',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/UserRanks.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/Users.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
    ]
  },
  {
    path: '/dashboard',
    //component: Dashboard,
    component: () => import(/* webpackChunkName: "dashboard" */'../views/Dashboard.vue'), 
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access_token")) {
        const token = localStorage.getItem("access_token");
        fetch(`${config.URL}/verifytoken`, {
          method: "GET",
          headers: {
            Authorization:'Bearer ' + token,
            "x-access-token": token,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                next();
              });
            }else{
              store.commit("disconnectUser");
              next('/');
            }
          })
          .catch((err) => {
            store.commit("disconnectUser"); next('/'); 
          });
      }else{
        store.commit("disconnectUser");
        next('/');
      }
    },
    meta: {
      hideNavbar: true,
      hideFooter: true
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        redirect:'/dashboard/captures',
        //component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Dashboard.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'history',
        name: 'history',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/History.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Subscriptions.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'images',
        name: 'images',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Images.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'captures',
        name: 'captures',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Images.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'collections',
        name: 'collections',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Collections.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      },
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/Account.vue'),
        meta: {
          hideNavbar: true,
          hideFooter: true
        }
      }
    ]
  },
  { 
    path: '/:catchAll(.*)', 
    redirect: '/404',
    name: 'NotFound'
  },
  {
    path: '/(.*)*',
    name: '404',
    redirect: '/404'
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
}
});

export default router;
